<template>
  <b-card-body>
    <b-row>
      <b-col>
        <item-statistics
          title="Servisteki Araçlar"
          :value="counters[0] | toNumber"
          color="light-success"
          icon="ClockIcon"
        />
      </b-col>
      <b-col>
        <item-statistics
          title="Dışarıdaki Araçlar"
          :value="counters[1] | toNumber"
          color="light-info"
          icon="EyeIcon"
        />
      </b-col>
      <b-col>
        <item-statistics
          title="Pert Araçlar"
          :value="counters[2] | toNumber"
          color="light-danger"
          icon="XCircleIcon"
        />
      </b-col>
      <b-col>
        <item-statistics
          title="Onarılamayan Araçlar"
          :value="counters[3] | toNumber"
          color="light-warning"
          icon="AlertCircleIcon"
        />
      </b-col>
    </b-row>
  </b-card-body>
</template>

<script>
import {
  BCardBody,
  BCol,
  BRow,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import ItemStatistics from '@/views/Damage_tracking/components/ItemStatistics'

export default {
  name: 'DamageTrackingCounters',
  components: {
    BCardBody,
    BRow,
    BCol,
    ItemStatistics,
  },
  computed: {
    counters() {
      return this.$store.getters['damageTracking/getCounters']
    },
  },
}
</script>

<style scoped>

</style>
