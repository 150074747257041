<template>
  <div>
    <b-card>
      <damage-tracking-filter
        :reset-search="resetSearch"
        :search-data="searchData"
      />
    </b-card>

    <b-card>
      <damage-tracking-counters />
    </b-card>
    <b-card
      no-body
    >
      <b-card-header>
        <b-card-title>Araç Listesi</b-card-title>
        <b-button
          variant="primary"
          to="damage-tracking/search"
        >
          <FeatherIcon icon="PlusIcon" />
          Oluştur

        </b-button>
      </b-card-header>
      <list-table
        :table-data="dataList"
        :remove-item="removeData"
      />
      <b-card-footer class="d-flex align-items-center justify-content-between">
        <b-pagination
          v-model="currentPage"
          :total-rows="dataCount"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
        <div class="text-muted font-small-2">
          Toplam {{ dataCount | toNumber }} adet takip kartı bulundu.
        </div>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard, BCardFooter, BCardHeader, BCardTitle, BPagination,
} from 'bootstrap-vue'
import ListTable from '@/views/Damage_tracking/components/listTable.vue'
import DamageTrackingCounters from '@/views/Damage_tracking/components/counters.vue'
import DamageTrackingFilter from '@/views/Damage_tracking/components/FilterCard.vue'

export default {
  name: 'DamageTracking',
  components: {
    BPagination,
    BCardFooter,
    DamageTrackingCounters,
    DamageTrackingFilter,
    ListTable,
    BButton,
    BCard,
    BCardHeader,
    BCardTitle,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      search: '',
      dataQuery: {
        limit: 25,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['damageTracking/getDataList']
    },
    dataCount() {
      return this.$store.getters['damageTracking/dataCount']
    },
    filterData() {
      return this.$store.getters['damageTracking/filterData']
    },
    dataSaveStatus() {
      return this.$store.getters['damageTracking/saveDataStatus']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * this.perPage
      this.pagination(page)
    },
    dataSaveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      return this.$store.dispatch('damageTracking/dataList', this.dataQuery)
    },
    searchData() {
      const where = {}
      if (this.filterData.keyword.length) {
        this.dataQuery.or_like = {
          'com_customer.name': this.filterData.keyword,
          'com_customer.company_name': this.filterData.keyword,
          'com_customer.phone': this.filterData.keyword,
          'com_damage_tracking.tracking_no': this.filterData.keyword,
          'com_cars.license_plate': this.filterData.keyword,
        }
      } else {
        this.dataQuery.or_like = {}
      }
      if (this.filterData.id_com_damage_tracking_status) {
        where['com_damage_tracking.id_com_damage_tracking_status'] = this.filterData.id_com_damage_tracking_status
      }
      if (this.filterData.id_com_damage_tracking_type) {
        where['com_damage_tracking.id_com_damage_tracking_type'] = this.filterData.id_com_damage_tracking_type
      }
      if (this.filterData.id_com_location) {
        where['com_damage_tracking.id_com_location'] = this.filterData.id_com_location
      }
      if (this.filterData.id_com_user) {
        where['com_damage_tracking.id_com_user'] = this.filterData.id_com_user
        this.perPage = 50
        this.dataQuery.limit = 50
      } else {
        this.perPage = 25
        this.dataQuery.limit = 25
      }
      if (this.filterData.sdate) {
        where['DATE(com_damage_tracking.created) >='] = this.filterData.sdate
      }
      if (this.filterData.edate) {
        where['DATE(com_damage_tracking.created) <='] = this.filterData.edate
      }
      this.dataQuery.where = where
      this.getDataList()
    },
    resetSearch() {
      this.dataQuery.or_like = {}
      this.search = ''
      this.getDataList()
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('damageTracking/removeData', id)
            .then(res => {
              if (res) {
                this.$swal({
                  icon: 'success',
                  title: this.$store.state.app.removeResultTitle,
                  text: this.$store.state.app.removeResultMessage,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.$store.dispatch('damageTracking/dataList', this.dataQuery)
              }
            })
        }
      })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
  },
}
</script>
