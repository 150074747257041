<template>
  <div>
    <b-row class="d-flex align-items-center match-height">
      <b-col>
        <b-input-group>
          <b-form-input
            v-model="filterData.keyword"
            placeholder="Müşteri Kartı,İş Emri,Plaka girerek arama yapabilirsiniz.."
            @keydown.enter="searchData"
          />
          <b-input-group-append>
            <b-button
              v-if="filterData.keyword.length"
              variant="warning"
              @click="resetSearch"
            >
              <FeatherIcon icon="XIcon" />
            </b-button>
            <b-button
              variant="primary"
              @click="searchData"
            >
              <FeatherIcon icon="SearchIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="auto">
        <b-form-checkbox
          v-model="filterData.detailSearch"
          switch
          @change="resetFilter"
        >
          Detaylı Arama
        </b-form-checkbox>
      </b-col>
    </b-row>
    <template v-if="filterData.detailSearch">
      <hr>
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Takip Tipi"
            label-for="id_com_damage_tracking_type"
          >
            <v-select
              id="id_com_damage_tracking_status"
              v-model="filterData.id_com_damage_tracking_type"
              :options="typesData"
              label="title"
              :reduce="item => item.id"
              placeholder="Seçiniz"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Durum"
            label-for="id_com_damage_tracking_status"
          >
            <v-select
              id="id_com_damage_tracking_status"
              v-model="filterData.id_com_damage_tracking_status"
              :options="status"
              label="title"
              :reduce="item => item.id"
              placeholder="Seçiniz"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Lokasyon"
            label-for="id_com_location"
          >
            <v-select
              id="id_com_location"
              v-model="filterData.id_com_location"
              :options="location"
              label="title"
              :reduce="item => item.id"
              placeholder="Seçiniz"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Başlangıç Tarihi"
            label-for="sdate"
          >
            <b-input-group>
              <b-form-datepicker
                id="sdate"
                v-model="filterData.sdate"
                v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                locale="tr"
                start-weekday="1"
              />
              <b-input-group-append v-if="filterData.sdate">
                <b-button
                  variant="outline-primary"
                  @click="filterData.sdate = null"
                >
                  <FeatherIcon icon="XCircleIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Bitiş Tarihi"
            label-for="sdate"
          >
            <b-input-group>
              <b-form-datepicker
                id="edate"
                v-model="filterData.edate"
                v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                locale="tr"
                start-weekday="1"
              />
              <b-input-group-append v-if="filterData.edate">
                <b-button
                  variant="outline-primary"
                  @click="filterData.edate = null"
                >
                  <FeatherIcon icon="XCircleIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Danışman"
            label-for="id_com_user"
          >
            <v-select
              id="id_com_user"
              v-model="filterData.id_com_user"
              :options="users"
              label="name"
              :reduce="item => item.id"
              placeholder="Seçiniz"
            >
              <template v-slot:option="option">
                <div v-if="option.user_type">
                  {{ option.name }}
                  <div>
                    <small class="text-warning">{{ option.brand }}</small>
                    <div>
                      <small class="text-secondary">{{ option.user_type }}</small>
                    </div>
                  </div>
                </div>
                <div v-else>
                  {{ option.name }}
                </div>
              </template>
              <template v-slot:selected-option="option">
                {{ option.name }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="text-center"
        >
          <b-button
            variant="warning"
            @click="searchData"
          >
            <FeatherIcon icon="FilterIcon" />
            Filtrele
          </b-button>
        </b-col>
      </b-row>
    </template>
  </div>
</template>
<script>
import {
  BButton, BFormInput, BInputGroup, BInputGroupAppend, BRow, BCol, BFormCheckbox, BFormDatepicker, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'DamageTrackingFilter',
  components: {
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BRow,
    BCol,
    BFormCheckbox,
    BFormDatepicker,
    BFormGroup,
    vSelect,
  },
  props: {
    searchData: {
      type: Function,
      required: true,
    },
    resetSearch: {
      type: Function,
      required: true,
    },
  },
  computed: {
    filterData() {
      return this.$store.getters['damageTracking/filterData']
    },
    status() {
      return this.$store.getters['damageTrackingStatus/getDataList']
    },
    location() {
      return this.$store.getters['locations/getLocations']
    },
    typesData() {
      return this.$store.getters['damageTrackingType/getDataList']
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
  },
  created() {
    this.setStatus()
    this.setTypes()
    this.setUsers()
    this.setLocations()
  },
  methods: {
    resetFilter() {
      if (!this.filterData.detailSearch) {
        this.filterData.id_com_damage_tracking_status = null
        this.filterData.id_com_location = null
        this.filterData.sdate = null
        this.filterData.edate = null
      }
    },
    setStatus() {
      return this.$store.dispatch('damageTrackingStatus/dataList')
    },
    setTypes() {
      return this.$store.dispatch('damageTrackingType/dataList')
    },
    setUsers() {
      return this.$store.dispatch('users/usersList', {
        select: [
          'com_user.id as id',
          'com_user.name as name',
          'com_user_type.title as user_type',
          'com_brand.name as brand',
        ],
        where:
        {
          'com_user.status': 1,
          // 'com_user.id_com_department': 3,
          // 'com_user.id_com_user_type': 19,
        },
      })
    },
    setLocations() {
      this.$store.dispatch('locations/locationsList', {
        select: [
          'com_location.id AS id',
          'com_location.title AS title',
        ],
        where: {
          'com_location.service_status': '1',
        },
        order_by: [
          'com_location.id', 'ASC',
        ],
      })
    },
  },
}
</script>
