<template>
  <b-table
    v-if="tableData.length > 0"
    striped
    hover
    responsive
    :fields="fields"
    :items="tableData"
  >
    <template #cell(customer)="data">
      <div v-if="data.item.tracking_no">
        <small class="text-warning">
          <feather-icon icon="ClipboardIcon" />
          {{ data.item.tracking_no }}
        </small>
      </div>
      {{ data.item.customer? data.item.customer : data.item.company ? data.item.company : '-' }}
    </template>
    <template #cell(entry_date)="data">
      {{ data.item.entry_date? moment(data.item.entry_date).format('DD.MM.YYYY') : '-' }}
      <div v-if="data.item.release_date">
        <small class="text-primary">Tahmini Çıkış:  {{ data.item.release_date? moment(data.item.release_date).format('DD.MM.YYYY') : '-' }} </small>
      </div>
    </template>
    <template #cell(model)="data">
      {{ data.item.model }}
      <div v-if="data.item.license_plate">
        <small class="text-warning">{{ data.item.license_plate }}</small>
      </div>
      <div v-if="data.item.damage_tracking_insurance">
        <small class="text-primary">{{ data.item.damage_tracking_insurance }}</small>
      </div>
      <div v-if="data.item.damage_tracking_type">
        <small class="text-muted">{{ data.item.damage_tracking_type }}</small>
      </div>
      <div v-if="data.item.location">
        <small class="text-muted">{{ data.item.location }}</small>
      </div>
    </template>
    <template #cell(last_status)="data">
      {{ data.item.last_status? data.item.last_status : '-' }}
      <div v-if="moment().diff(moment(data.item.entry_date),'days')">
        <small class="text-danger">{{ moment().diff(moment(data.item.entry_date),'days') }} Gün</small>
      </div>
      <div v-if="data.item.vip">
        <small class="text-warning">
          <feather-icon icon="StarIcon" />
          Vip Araç
        </small>
      </div>
      <div v-if="data.item.username">
        <small class="text-secondary">{{ data.item.username }} </small>
      </div>
    </template>
    <template #cell(control)="data">
      <b-dropdown
        v-if="allowedUpdate.includes(userData.id_com_user_type)"
        :split-to="'/damage-tracking/view/' + data.item.id"
        split
        block
        size="sm"
        text="Görüntüle"
        variant="outline-primary"
      >
        <b-dropdown-item
          :to="'/damage-tracking/edit/' + data.item.id"
        >
          Güncelle
        </b-dropdown-item>
        <b-dropdown-item
          v-if="userData.id_com_user_type === '1'"
          @click="removeItem(data.item.id)"
        >
          Sil
        </b-dropdown-item>
      </b-dropdown>
      <b-button
        v-else
        :to="'/damage-tracking/view/' + data.item.id"
        variant="outline-primary"
        block
        size="sm"
      >
        Görüntüle
      </b-button>
    </template>
  </b-table>
  <b-alert
    v-else
    show
    class="ml-2 mr-2"
  >
    <div class="alert-body text-center">
      <FeatherIcon icon="InfoIcon" />
      Kayıt bulunmamaktadır.
    </div>
  </b-alert>
</template>
<script>

import {
  BAlert,
  BButton, BDropdown, BDropdownItem, BTable, BBadge,
} from 'bootstrap-vue'

export default {
  name: 'ListTable',
  components: {
    BAlert,
    BDropdownItem,
    BDropdown,
    BButton,
    BTable,
    BBadge,
  },
  props: {
    tableData: {
      type: Array,
      require: true,
    },
    removeItem: {
      type: Function,
      require: true,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      allowedUpdate: ['1', '17', '18', '19'],
      fields: [
        {
          key: 'customer',
          label: 'Müşteri',
          tdClass: 'text-nowrap font-small-3',
        },
        {
          key: 'model',
          label: 'Araç',
          tdClass: 'text-nowrap font-small-3',
        },
        {
          key: 'entry_date',
          label: 'Giriş Tarihi',
          tdClass: 'text-nowrap font-small-3',
        },
        {
          key: 'last_status',
          label: 'Son Durum',
          tdClass: 'text-nowrap font-small-3',
        },
        {
          key: 'control',
          label: 'Kontrol',
          thStyle: { width: '140px' },
          tdClass: 'width-100 text-nowrap font-small-3',
        },
      ],
    }
  },
}
</script>
